import React from 'react'
import { Dropdown, Form } from 'semantic-ui-react'
// import FormErrorView from './FormErrorView'

export default class Select extends React.Component {
  constructor(props) {
    super(props)

    this.state = {
      options: [],
      loading: false,
    }
  }

  componentDidMount() {
    if (this.props.value && this.props.getByValue) {
      this.getByValue()
    } else if (this.props.loadOnMount) {
      this.loadOptions()
    }
  }

  componentDidUpdate(prevProps) {
    // Verify if value props change
    if (prevProps.value !== this.props.value) {
      if (!this.state.options.find(({ value }) => value === this.props.value)) {
        this.getByValue()
      }
    } else if (this.props.refresh) {
      this.setState({ options: [] })
      this.loadOptions()
    }
  }

  shouldComponentUpdate(nextProps, nextState) {
    return (
      nextProps.value !== this.props.value ||
      nextProps.error !== this.props.error ||
      nextProps.disabled !== this.props.disabled ||
      nextProps.refresh !== this.props.refresh ||
      nextProps.label !== this.props.label ||
      nextState !== this.state
    )
  }

  mergeOptions = options => {
    const missionOptions = options.filter(
      option => !this.state.options.find(({ value }) => value === option.value)
    )
    this.setState(prevState => ({
      options: missionOptions.concat(prevState.options),
      loading: false,
    }))
  }

  loadOptions = (query = '') => {
    this.setState({ loading: true })
    this.props
      .loadOptions(query || '')
      .then(options => this.mergeOptions(options))
  }

  getByValue = () => {
    if (this.props.getByValue && this.props.value) {
      this.props
        .getByValue(this.props.value)
        .then(options => this.mergeOptions(options))
    }
  }

  onSearchChange = event => {
    this.loadOptions(event.target.value)
  }

  render() {
    const { label } = this.props
    const style = {}
    const error = this.props.error ? true : false
    const props = { ...this.props }

    if (props.warning && !props.error) {
      style.background = '#fffaf3'
    }

    if (props.transparent) {
      // style.background = 'rgb(255, 250, 243)'
      style.border = '0px'
    }

    delete props.label
    delete props.transparent
    delete props.warning
    delete props.error
    delete props.loadOptions
    delete props.getByValue
    delete props.loadOnMount
    delete props.refresh
    delete props.touched

    return (
      <Form.Field error={error} required={this.props.required}>
        {label && <label>{label}</label>}

        <Dropdown
          {...props}
          placeholder={props.placeholder || label}
          style={style}
          options={this.state.options}
          loading={this.state.loading}
          onSearchChange={this.onSearchChange}
          onChange={(_, { value }) => props.onChange(value)}
        />

        {/* {error && <FormErrorView error={this.props.error} />} */}
      </Form.Field>
    )
  }
}

Select.defaultProps = {
  clearable: true,
  fluid: true,
  search: true,
  multiple: false,
  selection: true,
  selectOnBlur: false,
  selectOnNavigation: false,
  noResultsMessage: null,
  transparent: false,
  warning: false,
  loadOnMount: false,
  onChange: () => { },
}
